<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div style="height:100px;width:100vw;">

    </div>
    <PanelA></PanelA>
    <PanelB></PanelB>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import PanelA from './components/PanelA.vue';
import PanelB from './components/PanelB.vue';

export default {
  name: 'Home',
  components: {
    NavHeader, NavHeaderMobile, NewFooter, PanelB, PanelA,
  },
  data() {
    return {
    };
  },
};
</script>
