
<template>
  <div class="integration_container" :class="{out: isHome}">
    <div class="top">
      <div class="banner__left">
        <div class="g-title title">Secure digital asset <br>custody</div>
        <!-- <span class="tip"> Instant deposit, withdrawal, and exchange </span> -->
        <div class="list-details-container">
          <div class="list-details">
            <img src="@/assets/circle-b.svg" alt="" />
            <div class="details">Secure Multi-Signature Wallet</div>
          </div>
          <div class="list-details">
            <img src="@/assets/circle-b.svg" alt="" />
            <div class="details">
              Customisable Roles and Approval Levels
            </div>
          </div>
          <div class="list-details">
            <img src="@/assets/circle-b.svg" alt="" />
            <div class="details">
              Institutional Grade Controls and Security Measures
            </div>
          </div>
          <div class="list-details">
            <img src="@/assets/circle-b.svg" alt="" />
            <div class="details">
              Digital Asset Standard Agnostic Enables Scalability
            </div>
          </div>
          <div class="list-details">
            <img src="@/assets/circle-b.svg" alt="" />
            <div class="details">Built to be Flexible and Reliable</div>
          </div>
          <div class="list-details">
            <img src="@/assets/circle-b.svg" alt="" />
            <div class="details">Seamless User Experience</div>
          </div>
        </div>
      </div>
      <div class="banner__right">
        <img src="@/assets/panel/panela.png" class="inst_card_m" alt="" />
        <!-- <div class="go_wallet">
          <div class="g-link-clear btn btn--contact" @click="goWallet">
            Go ahead wallet
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CryptoWallet',
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goWallet() {
      this.$router.push('/my');
    },
  },
};
</script>

<style lang="scss" scoped>
.integration_container {
  width: 100%;
  padding: 160px 0;
  background: #fafafa;
  &.out{
    background:linear-gradient(to bottom, #ccd7fc 5%, #fff);

  }
  padding: 40px;
  .top {
    display: flex;
    max-width: 1180px;
    margin: auto;
    justify-content: center;
    align-items: center;
    @media (max-width: 1080px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0;
      padding: 20px;
    }
  }
}

.banner__left {
  max-width: 420px;
  margin-right: 64px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
  }
}

.banner__right {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  @media (max-width: 750px) {
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .tag {
    display: flex;
    padding: 40px 0 20px;
    border-bottom: 1px solid;
    width: 100%;
    border-image: linear-gradient(
        to right,
        rgba(0, 114, 206, 1),
        rgba(229, 64, 255, 1)
      )
      1 0;
    .tag_title {
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.3;
      width: 100px;
      cursor: pointer;
      width: 50%;
      text-align: center;
      @media (max-width: 1080px) {
        font-size: 16px;
      }
    }
    .tag_title_blue {
      color: #fff;
      opacity: 1;
    }
  }
  .inst_card_m {
    display: block;
    width: 100%;
    @media (max-width: 1080px) {
      margin-top: 40px;
    }
  }
  .go_wallet {
    margin-top: 30px;
    text-align: center;
    @media (max-width: 1080px) {
      text-align: center;
    }
    .btn--contact {
      border: 1px solid #0045ff;
      background: #0045ff;
      color: #fff;
      box-sizing: border-box;
      display: inline-block;
      padding: 0 40px;
      height: 60px;
      width: 320px;
      line-height: 58px;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
      font-size: 18px;
      opacity: 1;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 1.5;
  width: 620px;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 36px;
  }
  @media (max-width: 520px) {
    width: 100%;
    text-align: center;
    text-align: left;
    font-weight: bold;
    font-size: 24px;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  width: 100%;
  display: block;
  @media (max-width: 520px) {
    margin: 24px 0;
    display: block;
  }
}
.list-details-container {
  margin-top: 80px;
  @media (max-width: 520px) {
    margin-top: 30px;
  }
  .list-details {
    display: flex;
    margin-bottom: 30px;
    img {
      margin-right: 20px;
    }
    .details {
      font-weight: 400;
      font-size: 16px;
      @media (max-width: 520px) {
        font-size: 12px;
      }
    }
  }
}
</style>
