
<template>
  <div class="integration_container">
    <div class="top">
      <div class="banner__right">
        <img src="@/assets/panel/panelc.png" class="inst_card_m" alt="" />
      </div>
      <div class="banner__left">
        <div class="g-title title">Security, Convenience and Compliance</div>
        <span class="tip">
          The digital asset custody platform designed to offer the safekeeping
          of institutional digital assets. The offers a global, standardized,
          resilient, insured, and compliant custody service for the safekeeping
          of institutional digital asset investments, enabling clients to have
          access to a reliable platform with a flexible infrastructure designed
          to meet the possible futures of crypto custody.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Integration',
};
</script>

<style lang="scss" scoped>
.integration_container {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    margin: auto;

    @media (max-width: 1024px) {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      margin: 0;
      padding: 10px 8px;
    }
  }
}

.banner__left {
  max-width: 520px;
  @media (max-width: 1024px) {
    width: 100%;

  }
}

.banner__right {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-top:30px;
    width: 540px;
  }
  margin-right: 64px;
  margin-bottom: 40px;
  @media (max-width: 1080px) {
    justify-content: center;
    align-items: center;
    margin-right: 0;

  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  width: 620px;
  margin-bottom: 10px;
  @media (max-width: 520px) {
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
}

.tip {
  margin-top:40px;
  max-width: 420px;
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  width: 100%;
  display: block;
  @media (max-width: 520px) {
    margin: 24px 0;
    font-size: 18px;
    display: block;
  }
}

.l-card-table {
  margin-top: 200px;
}

.applyCard {
  margin-top: 40px;
  @media (max-width: 1080px) {
    text-align: center;
  }
  .btn--contact {
    border: 1px solid #0045ff;
    background: #0045ff;
    color: #fff;
    box-sizing: border-box;
    display: inline-block;
    padding: 0 40px;
    height: 60px;
    width: 320px;
    line-height: 58px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    opacity: 1;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
